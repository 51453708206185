import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../sass/app.scss'

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import bg from "../images/bg.png"
import bgmobile from "../images/bg-mobile.png"

const NotFoundPage = ({ data }) => {
  const breakpoints = useBreakpoint();
  var image;

  if (breakpoints.mobile) {
    image = bgmobile
  } else {
    image = bg
  }

  const shareContact = () => {
    if (typeof document !== 'undefined' && document !== 'undefined')
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      alert('Windows')
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      alert('Android')
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      alert('iOS')
      // Get the contact information from the website
      var contact = {
        name: "João Queirós",
        phone: "+351 968 732 504",
        email: "jqueiros@pontourbano.pt"
      };
      // create a vcard file
      var vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEMAIL:" + contact.email + "\nEND:VCARD";
      var blob = new Blob([vcard], { type: "text/vcard" });
      var url = URL.createObjectURL(blob);

      const newLink = document.createElement('a');
      newLink.download = contact.name + ".vcf";
      newLink.textContent = contact.name;
      newLink.href = url;

      newLink.click();
      return "iOS";
    }
  }

  return (
    <Layout>
      <Seo title="João Queirós" />
      <div className="fullWidth" style={{ backgroundImage: `url(${image})` }}>
        {breakpoints.md || breakpoints.ipad ? (
          <>
            <div className="div-person-mobile">
              <StaticImage
                src="../images/person.png"
                loading="eager"

                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
                style={{}}
              />
              <div className="div-info">
                <div className="block margin-top-minus">
                  <p className="name">
                    {data.globalJson.info.name}
                  </p>
                  <p className="info">
                    {data.globalJson.info.department}
                  </p>
                  <a href="https://joao-queiros.pontourbano.pt/vcard.vcf" download="joao_queiros" className='add-contact-button'>
                    <StaticImage
                      src="../images/icon-download.png"
                      loading="eager"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt=""
                      style={{ height: '100%', width: 'auto', marginTop: '2vh' }}
                    />
                  </a>
                </div>

              </div>
            </div>
            <div className="div-social-mobile">
              {data.globalJson.social.map((social, l) => (
                <a href={social.link} target="_blank" key={"social" + l}>
                  <GatsbyImage image={getImage(social.img)}
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    alt={social.alt}
                  />
                </a>

              ))}

            </div>
            <div className="div-clique-mobile">

              <StaticImage
                src="../images/clique.png"
                loading="eager"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
                style={{}}
              />
            </div>
          </>)
          :
          (

            <>
              <div className="div-person">
                <StaticImage
                  src="../images/person.png"
                  loading="eager"

                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  style={{}}
                />
              </div>
              <div className="div-info">
                <div className="block margin-top-minus">
                  <p className="name">
                    {data.globalJson.info.name}
                  </p>
                  <p className="info">
                    {data.globalJson.info.department}
                  </p>

                </div>

              </div>
              <div className="div-social">
                {data.globalJson.social.map((social, l) => (
                  <a href={social.link} target="_blank" key={"social" + l}>
                    <GatsbyImage image={getImage(social.img)}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt={social.alt}
                    />
                  </a>

                ))}

              </div>
              <div className="div-clique">
                <StaticImage
                  src="../images/clique.png"
                  loading="eager"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  style={{}}
                />
              </div>
            </>
          )}


      </div>


    </Layout>
  )
}


export default NotFoundPage


export const IndexQuery = graphql`
  query notfoundJson {
    globalJson {
      info{
        name
        department
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      social{
        alt
        link
        img{
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
